body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.skill-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.skill-card {
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.skill-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.skill-name {
  font-size: 1em;
}


.portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}


.project-card {
  width: 300px;
  height: 200px;
  background-size: cover;
  position: relative;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
}

.project-info {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* semi-transparent black */
  color: white;
  width: 100%;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}

.project-card:hover .project-info {
  transform: translateY(0);
}

.project-info h3, .project-info p {
  color: #d3d3d3; /* Light grey color */
  padding: 10px;
  margin: 0;
}
